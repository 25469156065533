import React from "react"
import { Text } from "@opensea/ui-kit"
import { Modal } from "@/design-system/Modal"
import { MultiStepModalProps } from "@/design-system/Modal/MultiStepModal.react"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { Action, useTrackInvalidOfferModal } from "./analytics"

type Props = Pick<MultiStepModalProps, "onPrevious" | "onClose"> & {
  action?: Action
}

export const InvalidOrderModalContent = ({
  onPrevious,
  onClose,
  action,
}: Props) => {
  const trackInvalidOfferModal = useTrackInvalidOfferModal()
  useMountEffect(() => {
    trackInvalidOfferModal({
      action,
    })
  })

  const t = useTranslate("components")
  return (
    <>
      <Modal.Header onPrevious={onPrevious}>
        <Modal.Header.Title>
          {t("trade.invalid.title", "Action Failed")}
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Text asChild>
          <p>
            {t(
              "trade.invalid.description1",
              "It looks like this order is no longer valid.",
            )}
          </p>
        </Text>
        <Text asChild>
          <p>
            {t(
              "trade.invalid.description2",
              "Please refresh the page and try again.",
            )}
          </p>
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button variant="secondary" onClick={onClose}>
          {t("trade.invalid.close", "Close")}
        </Modal.Footer.Button>
        <Modal.Footer.Button
          icon="refresh"
          onClick={() => window.location.reload()}
        >
          {t("trade.invalid.refresh", "Refresh")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}
