import { getItemTrackingFnWithExtraParamsV2 } from "@/lib/analytics/events/itemEvents"
import { useTrackingFn } from "@/lib/analytics/hooks/useTrackingFn"

export type Source =
  | "item page"
  | "sell page"
  | "listings table"
  | "offers table"
  | "item card"
  | "portfolio table collapsed row"
  | "portfolio table expanded row"
  | "profile item selection footer"

export type Action =
  | "Fulfill action"
  | "Quick Buy"
  | "Fulfill Deal"
  | "Accept Offer"

type InvalidOrderModalProperties = {
  action?: Action
}

export const trackClickAcceptOffer = getItemTrackingFnWithExtraParamsV2<{
  source: Source
}>("click accept offer")

export const trackClickAcceptHighestOffer = getItemTrackingFnWithExtraParamsV2<{
  source: Source
}>("click accept highest offer")

export const useTrackInvalidOfferModal = () =>
  useTrackingFn<InvalidOrderModalProperties>("Invalid Offer Modal was shown")
