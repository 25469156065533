import { MonthHeaderVariant } from "./Month.react"

export const getMonthHeaderVariant = (
  index: number,
  monthsToShow: number,
): MonthHeaderVariant => {
  if (monthsToShow === 1) {
    return "single"
  }
  if (index === 0) {
    return "first"
  }
  if (index === monthsToShow - 1) {
    return "last"
  }
  return "title-only"
}
