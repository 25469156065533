import React from "react"
import { Icon, VerticalAligned, Input, InputProps } from "@opensea/ui-kit"
import { format, set } from "date-fns"
import styled from "styled-components"

export type TimeInputProps = Omit<
  InputProps,
  "value" | "onChange" | "min" | "max"
> & {
  className?: string
  min?: Date
  max?: Date
  value?: Date
  onChange: (value: Date) => unknown
}

const HTML_TIME_INPUT = "HH:mm"

export const TimeInput = ({
  className,
  min,
  max,
  value,
  onChange,
  ...inputProps
}: TimeInputProps) => {
  return (
    <StyledInput
      className={className}
      max={max ? format(max, HTML_TIME_INPUT) : undefined}
      min={min ? format(min, HTML_TIME_INPUT) : undefined}
      startEnhancer={
        <VerticalAligned className="mr-2">
          <Icon className="text-secondary" value="schedule" />
        </VerticalAligned>
      }
      type="time"
      value={value ? format(value, HTML_TIME_INPUT) : undefined}
      onChange={event => {
        const v = event.target.value

        const [hours, minutes] = v.split(":")

        v &&
          onChange(
            set(value ?? new Date(), {
              hours: Number(hours),
              minutes: Number(minutes),
            }),
          )
      }}
      {...inputProps}
    />
  )
}

const StyledInput = styled(Input)`
  min-width: 160px;

  input[type="time"] {
    min-width: 100px;
    line-height: unset;
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }

    &::-webkit-datetime-edit {
      position: relative;
    }

    &::-webkit-date-time-edit-field,
    &::-webkit-datetime-edit-ampm-field {
      position: absolute;
      right: 10px;
    }

    &::-webkit-datetime-edit-text {
      margin: 0 5px;
    }
  }
`
