import React, { CSSProperties, useState } from "react"
import {
  Media,
  useIsLessThanSm,
  Text,
  classNames,
  Label,
  SpaceBetween,
  FlexColumn,
  Flex,
} from "@opensea/ui-kit"
import {
  addMonths,
  differenceInMinutes,
  isAfter,
  isBefore,
  isSameDay,
} from "date-fns"
import { range } from "lodash"
import { useUpdateEffect } from "react-use"
import { TimeInput } from "@/components/forms/TimeInput.react"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import { Month, getMonthHeaderVariant } from "./components/Month"

export type CalendarProps = {
  date?: Date
  endDate?: Date
  min?: Date
  max?: Date
  withTime?: boolean
  withEndDate?: boolean
  onChange: (date: Date, endDate: Date | undefined) => unknown
  monthsToShow?: number
  width?: CSSProperties["width"]
  previousButtonRef?: React.ForwardedRef<HTMLButtonElement>
  nextButtonRef?: React.ForwardedRef<HTMLButtonElement>
}

export const Calendar = ({
  date,
  endDate,
  min,
  max,
  withTime,
  withEndDate,
  onChange,
  monthsToShow = 1,
  width = "fit-content",
  previousButtonRef,
  nextButtonRef,
}: CalendarProps) => {
  const [currentDate, setCurrentDate] = useState(date ?? new Date())
  const t = useTranslate("designSystem")

  const isLessThanSm = useIsLessThanSm()

  useUpdateEffect(() => date && setCurrentDate(date), [date])

  const onSelectDate = (selectedDate: Date) => {
    if (
      !withEndDate ||
      (endDate && isAfter(selectedDate, endDate)) ||
      (date && isBefore(selectedDate, date))
    ) {
      onChange(selectedDate, undefined)
      return
    }
    if (date) {
      onChange(date, selectedDate)
    }
  }

  const onChangeStartTime = (dateWithTime: Date) => {
    onChange(dateWithTime, endDate)
  }

  const onChangeEndTime = (dateWithTime: Date) => {
    onChange(date ?? new Date(), dateWithTime)
  }

  const endTimeHasError =
    withEndDate &&
    endDate &&
    date &&
    isSameDay(endDate, date) &&
    differenceInMinutes(endDate, date) <= 14

  return (
    <Block width={width}>
      <Flex className="w-full">
        {range(monthsToShow).map(index => (
          <Block key={index} width={monthsToShow === 1 ? "100%" : undefined}>
            <Month.Header
              date={addMonths(currentDate, index)}
              max={max}
              min={min}
              nextButtonRef={nextButtonRef}
              previousButtonRef={previousButtonRef}
              variant={getMonthHeaderVariant(index, monthsToShow)}
              onChange={setCurrentDate}
            />
            <Month
              date={addMonths(currentDate, index)}
              max={max}
              min={min}
              selectedDate={date}
              selectedEndDate={withEndDate ? endDate : undefined}
              onSelect={onSelectDate}
            />
          </Block>
        ))}
      </Flex>
      {withTime && (
        <SpaceBetween className="flex-col sm:flex-row">
          <FlexColumn className="w-full max-w-[270px] p-4">
            <Media lessThan="sm">
              {mediaClassNames => (
                <Label
                  className={classNames("mb-2", mediaClassNames)}
                  htmlFor="start-time"
                  visuallyHidden={!isLessThanSm}
                >
                  {t("calendar.startTime.label", "Start Time")}
                </Label>
              )}
            </Media>
            <TimeInput
              id="start-time"
              max={max && isSameDay(max, currentDate) ? max : undefined}
              min={min && isSameDay(min, currentDate) ? min : undefined}
              value={date}
              onChange={onChangeStartTime}
            />
          </FlexColumn>
          {withEndDate && (
            <>
              <Media greaterThanOrEqual="sm">
                {mediaClassNames => (
                  <Text.Body
                    className={classNames("mb-7 mt-auto", mediaClassNames)}
                    size="medium"
                  >
                    —
                  </Text.Body>
                )}
              </Media>
              <FlexColumn className="w-full max-w-[270px] p-4">
                <Media lessThan="sm">
                  {mediaClassNames => (
                    <Label
                      className={classNames("mb-2", mediaClassNames)}
                      htmlFor="end-time"
                      visuallyHidden={!isLessThanSm}
                    >
                      {t("calendar.endTime.label", "End Time")}
                    </Label>
                  )}
                </Media>
                <TimeInput
                  error={endTimeHasError}
                  id="end-time"
                  max={max && isSameDay(max, currentDate) ? max : undefined}
                  min={min && isSameDay(min, currentDate) ? min : undefined}
                  value={endDate}
                  onChange={onChangeEndTime}
                />
              </FlexColumn>
            </>
          )}
        </SpaceBetween>
      )}
    </Block>
  )
}
