import React from "react"
import {
  Icon,
  UnstyledButton,
  Text,
  SpaceBetween,
  CenterAligned,
} from "@opensea/ui-kit"
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  differenceInDays,
  add,
  getHours,
  getMinutes,
  isSameDay,
  getMonth,
  isWithinInterval,
  getDate,
  addMonths,
  set,
  format,
  isAfter,
} from "date-fns"
import _ from "lodash"
import styled, { css } from "styled-components"
import { MONTH_YEAR_FORMAT_STRING } from "@/constants/datetime"
import { Block } from "@/design-system/Block"
import { isWithinRange, weekdays } from "@/lib/helpers/datetime"

const NUM_DAYS_IN_WEEK = 7

const getWeeksForMonth = (date: Date): Date[][] => {
  const startMonth = startOfMonth(date)
  const endMonth = endOfMonth(date)
  const startWeek = startOfWeek(startMonth)
  const endWeek = endOfWeek(endMonth)
  const numDays = differenceInDays(endWeek, startWeek) + 1

  const dates = Array(numDays)
    .fill(null)
    .map((_, i) =>
      add(startWeek, {
        days: i,
        hours: getHours(date),
        minutes: getMinutes(date),
      }),
    )

  return _.chunk(dates, NUM_DAYS_IN_WEEK)
}

export type MonthProps = {
  date: Date
  min?: Date
  max?: Date
  selectedDate?: Date
  selectedEndDate?: Date
  onSelect: (date: Date) => unknown
}

export const MonthBase = ({
  date,
  min,
  max,
  selectedDate: _selectedDate,
  selectedEndDate,
  onSelect,
}: MonthProps) => {
  const weeks = getWeeksForMonth(date)
  const selectedDate = _selectedDate ?? new Date()

  return (
    <Block flex={1} padding="16px">
      <SpaceBetween role="presentation">
        {weekdays().map(day => (
          <DayHeader key={day}>{day}</DayHeader>
        ))}
      </SpaceBetween>
      <div role="grid">
        {weeks.map((daysOfWeek, i) => (
          <SpaceBetween key={i} role="row">
            {daysOfWeek.map(day => {
              const selected =
                isSameDay(day, selectedDate) ||
                (!!selectedEndDate && isSameDay(day, selectedEndDate))

              const inMonth = getMonth(day) === getMonth(date)
              const inSelectedRange =
                !!selectedEndDate &&
                isAfter(selectedEndDate, selectedDate) &&
                isWithinInterval(day, {
                  start: selectedDate,
                  end: selectedEndDate,
                })

              // To avoid dates off by seconds/mins and calculating as NOT within range
              // we check if it's the same day, and if so, use the same "day" Date as
              // start date as well as the day passed into the disabled checker for isWithinRange
              const isCurrentDayDate = isSameDay(day, new Date())
              const isWithinRangeStartDate = isCurrentDayDate ? day : min
              const isDisabledDay =
                !isWithinRange(day, {
                  start: isWithinRangeStartDate,
                  end: max,
                }) || !inMonth

              return (
                <Day
                  $inSelectedRange={inSelectedRange}
                  $selected={selected}
                  disabled={isDisabledDay}
                  key={day.toString()}
                  tabIndex={selected ? 0 : -1}
                  onClick={() => {
                    !selected && onSelect(day)
                  }}
                >
                  {inMonth ? getDate(day) : null}
                </Day>
              )
            })}
          </SpaceBetween>
        ))}
      </div>
    </Block>
  )
}

export type MonthHeaderVariant = "single" | "first" | "last" | "title-only"

type MonthHeaderProps = {
  date: Date
  min?: Date
  max?: Date
  variant?: MonthHeaderVariant
  onChange: (date: Date) => unknown
  previousButtonRef?: React.ForwardedRef<HTMLButtonElement>
  nextButtonRef?: React.ForwardedRef<HTMLButtonElement>
}

const MonthHeader = ({
  date,
  min,
  max,
  variant = "title-only",
  onChange,
  previousButtonRef,
  nextButtonRef,
}: MonthHeaderProps) => {
  const renderBackButton = () => {
    let monthBefore = endOfMonth(addMonths(date, -1))
    monthBefore = set(monthBefore, {
      minutes: date.getMinutes(),
      hours: date.getHours(),
      seconds: date.getSeconds(),
      milliseconds: date.getMilliseconds(),
    })

    if (!isWithinRange(monthBefore, { start: min, end: max })) {
      return null
    }

    return (
      <UnstyledButton
        ref={previousButtonRef}
        onClick={() => onChange(monthBefore)}
      >
        <Icon
          aria-label="Previous month"
          className="text-secondary"
          size={16}
          value="arrow_back"
        />
      </UnstyledButton>
    )
  }

  const renderNextButton = () => {
    let monthAfter = startOfMonth(addMonths(date, 1))
    monthAfter = set(monthAfter, {
      minutes: date.getMinutes(),
      hours: date.getHours(),
      seconds: date.getSeconds(),
      milliseconds: date.getMilliseconds(),
    })

    if (!isWithinRange(monthAfter, { start: min, end: max })) {
      return
    }

    return (
      <UnstyledButton ref={nextButtonRef} onClick={() => onChange(monthAfter)}>
        <Icon
          aria-label="Next month"
          className="text-secondary"
          size={16}
          value="arrow_forward"
        />
      </UnstyledButton>
    )
  }

  const isBackButtonShown = variant === "first" || variant === "single"
  const isNextButtonShown = variant === "last" || variant === "single"

  return (
    <SpaceBetween asChild className="w-full items-center p-4">
      <header>
        <CenterAligned>{isBackButtonShown && renderBackButton()}</CenterAligned>
        <Text.Body size="small" weight="semibold">
          {format(date, MONTH_YEAR_FORMAT_STRING)}
        </Text.Body>
        <CenterAligned>{isNextButtonShown && renderNextButton()}</CenterAligned>
      </header>
    </SpaceBetween>
  )
}

const baseDayStyles = css`
  width: 30px;
  height: 30px;
  margin: 2px;
  font-weight: 600;
  color: ${props => props.theme.colors.text.secondary};
`

const disabledStyles = css`
  pointer-events: none;
  opacity: 0.25;
`

const selectedStyles = css`
  color: ${props => props.theme.colors.text.primary};
  background-color: ${props => props.theme.colors.components.background.gray1};
`

const inSelectedRangeStyles = css`
  background-color: ${props => props.theme.colors.fog};
`

const hoverStyles = css`
  :hover {
    box-shadow: ${props =>
      props.theme.colors.components.elevation.level1.regular.shadow};
  }
`

const DayHeader = styled.div`
  ${baseDayStyles}
  font-size: 12px;
`

type DayProps = {
  $selected: boolean
  $inSelectedRange: boolean
}

const Day = styled(UnstyledButton).attrs<DayProps>(props => ({
  "aria-selected": props.$selected ? true : undefined,
}))<DayProps>`
  ${baseDayStyles}
  color: ${props => props.theme.colors.text.secondary};
  border-radius: ${props => props.theme.borderRadius.default};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  ${props => props.disabled && disabledStyles}
  ${props => (props.$selected ? selectedStyles : hoverStyles)}
  ${props => props.$inSelectedRange && inSelectedRangeStyles}
`

export const Month = Object.assign(MonthBase, {
  Header: MonthHeader,
})
