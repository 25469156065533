import { subMinutes } from "date-fns"
import { ordersWillReactivateListingsQuery } from "@/lib/graphql/__generated__/ordersWillReactivateListingsQuery.graphql"
import { fetch, graphql } from "@/lib/graphql/graphql"

export const checkWillReactivateListings = async (
  assetId: string,
  destinationAddress: string,
) => {
  const [{ assetTransferability }] =
    await fetch<ordersWillReactivateListingsQuery>(
      graphql`
        query ordersWillReactivateListingsQuery(
          $assetId: AssetRelayID!
          $toAddress: AddressScalar!
        ) {
          assetTransferability(asset: $assetId, toAddress: $toAddress) {
            willReactivateOrders
          }
        }
      `,
      { assetId, toAddress: destinationAddress },
    )

  return assetTransferability.willReactivateOrders
}

// This is done so there's enough buffer time for the order to be immediately purchased right after it gets posted.
// This is needed because there can be delays in the latest block time if the order was created with new Date() and someone
// tries to immediately purchase it
export const getDefaultOrderCreationOpenedAt = () => subMinutes(new Date(), 2)
