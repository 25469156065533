import React, { ComponentProps, useRef, useState } from "react"
import {
  SpaceBetween,
  breakpoints,
  Input,
  classNames,
  inputVariants,
} from "@opensea/ui-kit"
import { subYears, format as formatNativeDate, isValid } from "date-fns"
import { useClickAway, useKeyPressEvent, useUpdateEffect } from "react-use"
import styled from "styled-components"
import { Overflow } from "@/components/common/Overflow"
import {
  SHORT_DATE_FORMAT_STRING,
  SHORT_TIME_FORMAT_STRING,
} from "@/constants/datetime"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Calendar } from "@/design-system/Calendar"
import { Popover, PopoverProps } from "@/design-system/Popover"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"
import { isWithinRange } from "@/lib/helpers/datetime"
import { isInsideRef } from "@/lib/helpers/dom"

export const HTML_DATE_FORMAT = "yyyy-MM-dd"
export const HTML_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm"

const DUMMY_MAX_DATE = new Date("9999-12-31")

export const getMinDateOfBirth = () => subYears(new Date(), 100)

export type DatePickerProps = {
  disabled?: boolean
  placeholder?: string
  value?: Date
  hasInputError?: boolean
  min?: Date
  max?: Date
  withTime?: boolean
  autoFocus?: boolean
  name?: string
  overrides?: {
    Popover?: Pick<
      Partial<PopoverProps>,
      "popperOptions" | "appendTo" | "placement"
    >
    Button?: Partial<ComponentProps<typeof StyledButton>>
    Input?: Partial<ComponentProps<typeof StyledInput>> & {
      inputId?: string
    }
  }
  onChange?: (date: Date) => unknown
}

export const DatePicker = ({
  disabled,
  hasInputError,
  name,
  min,
  max,
  placeholder,
  value,
  withTime,
  onChange,
  overrides,
  autoFocus = true,
}: DatePickerProps) => {
  const format = withTime ? HTML_DATE_TIME_FORMAT : HTML_DATE_FORMAT
  const t = useTranslate("designSystem")
  const defaultPlaceholder = t("datePicker.placeholder", "Select a date")

  const formatDate = (date: Date) => {
    return formatNativeDate(date, format)
  }

  const [showInput, setShowInput] = useState(false)
  const [inputValue, setInputValue] = useState(value ? formatDate(value) : "")
  const popoverRef = useRef<HTMLDivElement>(null)
  const { isOpen, open, close } = useIsOpen()
  const containerRef = useRef<HTMLInputElement>(null)

  const previousButtonRef = useRef<HTMLButtonElement>(null)
  const nextButtonRef = useRef<HTMLButtonElement>(null)

  const onBlur = (target: EventTarget | null) => {
    if (!isInsideRef(popoverRef, target)) {
      close()
      setShowInput(false)
    }
  }

  useUpdateEffect(
    () => setInputValue(value ? formatDate(value) : ""),
    [value, format, isOpen],
  )

  useClickAway(containerRef, event => {
    onBlur(event.target)
  })

  useKeyPressEvent("Enter", event => {
    if (
      event.target === previousButtonRef.current ||
      event.target === nextButtonRef.current
    ) {
      return
    }

    onBlur(null)
  })

  return showInput ? (
    <Popover
      arrow={false}
      content={() => (
        <div
          ref={popoverRef}
          role="dialog"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          onBlur={event => onBlur(event.relatedTarget)}
        >
          <Calendar
            date={value}
            max={max}
            min={min}
            nextButtonRef={nextButtonRef}
            previousButtonRef={previousButtonRef}
            withTime={withTime}
            onChange={date => {
              if (!withTime) {
                onBlur(null)
              }
              onChange?.(date)
            }}
          />
        </div>
      )}
      contentPadding="0"
      placement="bottom"
      visible={isOpen}
      {...overrides?.Popover}
    >
      <StyledInput
        autoFocus={autoFocus}
        data-testid={overrides?.Input?.inputId || "date-picker-input"}
        disabled={disabled}
        error={hasInputError}
        id={overrides?.Input?.inputId || "date-picker-input"}
        // We use a dummy max date because HTML input dates allow 5+ digit years for some reason
        max={max ? formatDate(max) : formatDate(DUMMY_MAX_DATE)}
        min={min ? formatDate(min) : undefined}
        name={name}
        ref={containerRef}
        type={withTime ? "datetime-local" : "date"}
        value={inputValue}
        onBlur={event => onBlur(event.relatedTarget)}
        onChange={({ target: { value } }) => {
          setInputValue(value)

          const dateValue = new Date(value)
          if (
            isValid(dateValue) &&
            isWithinRange(dateValue, { start: min, end: max })
          ) {
            onChange?.(dateValue)
          }
        }}
        onFocus={open}
      />
    </Popover>
  ) : (
    <StyledButton
      className={classNames(
        "box-border h-12 border border-level-2 bg-transparent text-left hover:bg-transparent",
        inputVariants({ disabled, error: hasInputError }),
      )}
      data-testid={overrides?.Button?.id || "date-picker-button"}
      disabled={disabled}
      id={overrides?.Button?.id || "date-picker-button"}
      variant="secondary"
      onClick={() => !disabled && setShowInput(true)}
      onFocus={() => !disabled && setShowInput(true)}
      {...overrides?.Button}
    >
      <Overflow>
        {value ? (
          withTime ? (
            <SpaceBetween className="gap-1">
              <Block>{formatNativeDate(value, SHORT_DATE_FORMAT_STRING)}</Block>
              <Block>{formatNativeDate(value, SHORT_TIME_FORMAT_STRING)}</Block>
            </SpaceBetween>
          ) : (
            formatNativeDate(value, SHORT_DATE_FORMAT_STRING)
          )
        ) : (
          placeholder || defaultPlaceholder
        )}
      </Overflow>
    </StyledButton>
  )
}

const StyledInput = styled(Input)`
  input::-webkit-inner-spin-button,
  input::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: ${breakpoints.md}px) {
    padding-left: 12px;
    font-size: 14px;
  }
`
